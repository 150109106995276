/*================ Partials | Icons ================*/
@font-face {
  font-family: 'icons';
  src: url("//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/icons.eot?v=158399200609435563841510130796");
  src: url("//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/icons.eot?v=158399200609435563841510130796#iefix") format("embedded-opentype"), url("//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/icons.woff?v=169945158533379702731510130797") format("woff"), url("//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/icons.ttf?v=152698340250222789721510130796") format("truetype"), url("//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/icons.svg?v=1632698112070928441510130796#timber-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon-fallback-text .icon {
  display: none; }
  .supports-fontface .icon-fallback-text .icon {
    display: inline-block; }

/*============================================================================
  A generic way to visually hide content while
  remaining accessible to screen readers (h5bp.com)
==============================================================================*/
.supports-fontface .icon-fallback-text .fallback-text {
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }

.supports-fontface .icon:before {
  display: inline;
  font-family: "icons";
  text-decoration: none;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*================ Icon mapping ================*/
.icon-amazon_payments:before {
  content: "\e800"; }

.icon-american_express:before {
  content: "\41"; }

.icon-arrow-down:before {
  content: "\e607"; }

.icon-bitcoin:before {
  content: "\42"; }

.icon-cart:before {
  content: "\e600"; }

.icon-cirrus:before {
  content: "\43"; }

.icon-dankort:before {
  content: "\64"; }

.icon-diners_club:before {
  content: "\63"; }

.icon-discover:before {
  content: "\44"; }

.icon-dogecoin:before {
  content: "\e904"; }

.icon-dwolla:before {
  content: "\e905"; }

.icon-facebook:before {
  content: "\66"; }

.icon-fancy:before {
  content: "\46"; }

.icon-forbrugsforeningen:before {
  content: "\e906"; }

.icon-google-plus:before {
  content: "\e900"; }

.icon-grid-view:before {
  content: "\e603"; }

.icon-hamburger:before {
  content: "\e601"; }

.icon-instagram:before {
  content: "\e901"; }

.icon-interac:before {
  content: "\49"; }

.icon-jcb:before {
  content: "\4a"; }

.icon-list-view:before {
  content: "\e604"; }

.icon-litecoin:before {
  content: "\e908"; }

.icon-maestro:before {
  content: "\6d"; }

.icon-master:before {
  content: "\4d"; }

.icon-minus:before {
  content: "\e602"; }

.icon-paypal:before {
  content: "\50"; }

.icon-pinterest:before {
  content: "\70"; }

.icon-plus:before {
  content: "\e605"; }

.icon-rss:before {
  content: "\72"; }

.icon-search:before {
  content: "\73"; }

.icon-stripe:before {
  content: "\53"; }

.icon-tumblr:before {
  content: "\74"; }

.icon-twitter:before {
  content: "\54"; }

.icon-vimeo:before {
  content: "\76"; }

.icon-visa:before {
  content: "\56"; }

.icon-x:before {
  content: "\e606"; }

.icon-youtube:before {
  content: "\79"; }

/*================ Social share buttons ================*/
.social-sharing {
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*================ Large Buttons ================*/ }
  .social-sharing * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .social-sharing a {
    display: inline-block;
    color: #fff;
    border-radius: 2px;
    margin: 5px 10px 5px 0;
    height: 22px;
    line-height: 22px;
    text-decoration: none;
    font-weight: normal; }
    .social-sharing a:hover {
      color: #fff; }
  .social-sharing span {
    display: inline-block;
    vertical-align: top;
    height: 22px;
    line-height: 22px;
    font-size: 12px; }
  .social-sharing .icon {
    padding: 0 5px 0 10px; }
    .social-sharing .icon:before {
      line-height: 22px; }
  .social-sharing.is-large a {
    height: 44px;
    line-height: 44px; }
    .social-sharing.is-large a span {
      height: 44px;
      line-height: 44px;
      font-size: 18px; }
    .social-sharing.is-large a .icon {
      padding: 0 10px 0 18px; }
      .social-sharing.is-large a .icon:before {
        line-height: 44px; }

.share-title {
  font-weight: 900;
  font-size: 12px;
  padding-right: 10px; }
  .is-large .share-title {
    padding-right: 16px; }

.share-facebook {
  background-color: #3b5998; }
  .share-facebook:hover {
    background-color: #2d4373; }

.share-twitter {
  background-color: #00aced; }
  .share-twitter:hover {
    background-color: #0087ba; }

.share-pinterest {
  background-color: #cb2027; }
  .share-pinterest:hover {
    background-color: #9f191f; }

.share-fancy {
  background-color: #4999dc; }
  .share-fancy:hover {
    background-color: #2780cb; }

.share-google {
  background-color: #dd4b39; }
  .share-google:hover {
    background-color: #c23321; }

.share-count {
  position: relative;
  background-color: white;
  padding: 0 8px;
  margin-right: -2px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  border-radius: 0 2px 2px 0;
  border: 1px solid #ececec;
  opacity: 0; }
  .share-count.is-loaded {
    opacity: 1; }
  .share-count:before, .share-count:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid; }
  .share-count:before {
    left: -6px;
    border-right-color: #ececec; }
  .share-count:after {
    left: -5px;
    border-right-color: white; }
  a:hover .share-count {
    background-color: whitesmoke; }
    a:hover .share-count:after {
      border-right-color: whitesmoke; }
  .is-large .share-count {
    font-size: 18px;
    padding: 0 14px; }

/*================ Clean Buttons ================*/
.social-sharing.is-clean a {
  background-color: #fff;
  border: 1px solid #ececec;
  color: #333;
  height: 30px;
  line-height: 30px; }
  .social-sharing.is-clean a span {
    height: 30px;
    line-height: 30px;
    font-size: 13px; }
  .social-sharing.is-clean a:hover {
    background-color: #ececec; }
  .social-sharing.is-clean a:hover .share-count {
    background-color: white; }
    .social-sharing.is-clean a:hover .share-count:after {
      border-right-color: white; }
  .social-sharing.is-clean a .share-title {
    font-weight: normal; }
.social-sharing.is-clean .share-count {
  top: -1px; }
.social-sharing.is-clean .icon-facebook {
  color: #3b5998; }
.social-sharing.is-clean .icon-twitter {
  color: #00aced; }
.social-sharing.is-clean .icon-pinterest {
  color: #cb2027; }
.social-sharing.is-clean .icon-fancy {
  color: #4999dc; }
.social-sharing.is-clean .icon-google-plus {
  color: #dd4b39; }
